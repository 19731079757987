import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Privacy() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  scrollToTop();
  return (
    <div className='home'>
    <section className="info-section bg-purple">             
    <div className="head-box ons-doel">
    <Helmet>
              <title>Privacybeleid van de Digitale Burnout Applicatie ©</title>
              <meta name="description" content="Een Mobiele Applicatie waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken. Bovendien kan je genieten van premium features gericht op burnout preventie. De Digitale Burnout Applicatie © heeft ook een unieke chatbot de 'BURNout-bot'." />
            </Helmet>  
        <h3 className="text-purple text-center">
        PRIVACYBELEID<br></br>van de Digitale Burnout Applicatie ©
        </h3>                   
        <div className="head-box pt-2 pb-2 "> 
            <h5 className="text-orange px-2">Intellectuele eigendomsrechten</h5>
            <p>
            De inhoud van de burnout website en de Digitale Burnout Applicatie ® afgekort met DBA, met inbegrip van logo's, tekeningen, data, teksten, beelden e.d. zijn beschermd door intellectuele rechten en behoren toe aan DS Consult BV of rechthoudende derden.
            </p>
            <p>
            De Digitale Burnout Applicatie ® afgekort DBA is eigendom van: DS Consult BV, Beatrijslaan 4 te 2050 Antwerpen België. E-mail inconcreto@skynet.be 
            </p>
            <p>
            Door het bezoeken en het gebruik van de website en de DBA verklaart u zich uitdrukkelijk akkoord met de volgende algemene voorwaarden.
            </p>

            <h5 className="text-orange px-2">Beperking van aansprakelijkheid</h5>
            <p>
            De informatie op de website en de DBA is van algemene aard. De informatie is niet specifiek aangepast aan persoonlijke of specifieke omstandigheden, en kan dus niet als een persoonlijk, professioneel of juridisch advies aan de gebruiker worden beschouwd.
            </p>      
            <p>
            DS Consult BV levert grote inspanningen opdat de ter beschikking gestelde informatie volledig, juist, nauwkeurig en bijgewerkt zou zijn. Ondanks deze inspanningen kunnen onjuistheden zich voordoen in de ter beschikking gestelde informatie. Indien de verstrekte informatie onjuistheden zou bevatten of indien bepaalde informatie op of via de site onbeschikbaar zou zijn, zal DS Consult BV de grootst mogelijke inspanning leveren om dit zo snel mogelijk recht te zetten.
            </p>

            <p>
            DS Consult BV kan evenwel niet aansprakelijk worden gesteld voor rechtstreekse of onrechtstreekse schade die ontstaat uit het gebruik van de informatie op de DBA. Indien u onjuistheden zou vaststellen in de informatie die via de site ter beschikking wordt gesteld, kan u de beheerder van de site contacteren.
            </p>

            <p>
            De inhoud van de DBA (links inbegrepen) kan te allen tijde zonder aankondiging of kennisgeving aangepast, gewijzigd of aangevuld worden. DS Consult BV geeft geen garanties voor de goede werking van de DBA en kan op geen enkele wijze aansprakelijk gehouden worden voor een slechte werking of tijdelijke (on)beschikbaarheid van de DBA of voor enige vorm van schade, rechtstreekse of onrechtstreekse, die zou voortvloeien uit de toegang tot of het gebruik van de DBA.
            </p>

            <p>
            DS Consult BV kan in geen geval tegenover wie dan ook, op directe of indirecte, bijzondere of andere wijze aansprakelijk worden gesteld voor schade te wijten aan het gebruik van de DBA of van een andere, inzonderheid als gevolg van links of hyperlinks, met inbegrip, zonder beperking, van alle verliezen, werkonderbrekingen, beschadiging van programma's of andere gegevens op het computersysteem, van apparatuur, programmatuur of andere van de gebruiker. 
            </p>

            <p>
            De DBA kan hyperlinks bevatten naar websites of pagina's van derden, of daar onrechtstreeks naar verwijzen. Het plaatsen van links naar deze websites of pagina's impliceert op geen enkele wijze een impliciete goedkeuring van de inhoud ervan.        
            </p>

            <p>
            DS Consult BV verklaart uitdrukkelijk dat zij geen zeggenschap heeft over de inhoud of over andere kenmerken van de DBA en kan in geen geval aansprakelijk gehouden worden voor de inhoud of de kenmerken ervan of voor enige andere vorm van schade door het gebruik ervan.
            </p>

            <h5 className="text-orange px-2">Privacybeleid</h5>
            <p>
            DS Consult BV hecht veel belang aan uw privacy. Hoewel de meeste informatie op deze site
beschikbaar is zonder dat er persoonlijke gegevens moeten worden verstrekt, is het mogelijk
dat de gebruiker om persoonlijke informatie gevraagd wordt. Deze informatie zal enkel
gebruikt worden om u op de hoogte te houden van onze activiteiten.
            </p>   

            <p>
            De gebruiker kan zich, op verzoek, steeds verzetten tegen het gebruik van zijn gegevens.
Daartoe zal hij zich richten tot DS Consult BV. Uw persoonsgegevens worden nooit
doorgegeven aan derden.
            </p>

            <p>
            Conform de wet verwerking persoonsgegevens van 08/12/1992 beschikt de gebruiker over
een wettelijk recht op inzage en eventuele correctie van zijn persoonsgegevens. Indien nodig
kunt u ook vragen de gegevens te corrigeren die onjuist, niet volledig of niet pertinent
zouden zijn.
            </p>

            <p>
            DS Consult BV kan anonieme of geaggregeerde gegevens verzamelen van niet-persoonlijke
aard, zoals browser type of IP-adres, het besturingsprogramma dat u gebruikt of de
domeinnaam van de website langs waar u naar onze website gekomen bent, of waarlangs u
die verlaat. Dit maakt het ons mogelijk om onze website permanent te optimaliseren voor
de gebruikers.
            </p>


            <h5 className="text-orange px-2">Maakt de Digitale Burnout Applicatie ® gebruik van Cookies?</h5>
            <p>
            De DBA maakt géén gebruik van Cookies.
            </p>


            <h5 className="text-orange px-2">Gegevens die wij verzamelen</h5>
            <p>
            De DBA bestaat uit een WEB- en een MOBIELE Applicatie. Beide applicaties
zullen in dit Privacybeleid worden beschouwd als éénzelfde applicatie
genaamd "Digitale Burnout Applicatie ®" (afgekort “DBA”).
            </p>

            <p>
            De DBA is beschikbaar op verschillende platforms en wordt ondersteund voor
Android-besturingssystemen.
            </p>

            <p>
            Of je nu éénmalig of dagelijks gebruik maakt van de DBA, wensen we je
duidelijkheid en vertrouwen te bieden over hoe je gegevens worden
opgeslagen en beschermd.We wensen zo transparant mogelijk te zijn met
betrekking tot dit Privacybeleid inzake de gegevens die verzameld worden in
de DBA. 
            </p>

            <p>Graag leggen we uit hoe wij gegevens inzamelen, en hoe jij je gegevens kan
aanpassen, ophalen, beheren, downloaden, exporteren en verwijderen.

            </p>

            <p>
            Bovendien wensen we een duidelijk en overzichtelijk beeld te geven van de
gegevens die we opvragen, waarom we deze opvragen en hoe we deze
gegevens verwerken. We hopen dat je je vertrouwd voelt en een goed zicht
krijgt over wat er met je gegevens gebeurt, en hoe je dit kan nakijken en
aanpassen.
            </p>

            <p>
            Indien je een vraag of een opmerking hebt, vernemen wij dit graag via onze e-
mail inconcreto@skynet.be
            </p>

            <p>We hopen hiermee het wederzijdse vertrouwen te ondersteunen, waarbij je
steeds je gegevens kan nakijken, aanpassen en verwijderen.
            </p>



            <h5 className="text-orange px-2">Worden mijn persoonsgegevens ingezameld op deze website?</h5>
            <p>
            Neen. We vragen geen persoonsgegevens op. Er kunnen wel, indien je
expliciet toegang hebt gegeven, gebruikersinformaties worden ingezameld.
Maar deze bevatten geen persoonsgegevens.
            </p>
           


            <h5 className="text-orange px-2">Je gegevens beheren</h5>
            <p>Onafhankelijk van het platform dat je gebruikt, als je toegang hebt tot de DBA
en beschikt over een internetverbinding, kan je op elk moment je gegevens
beheren.
            </p>

            <ul>
                <li>Je kan op elk moment je profiel consulteren om je persoonlijke
gegevens te bekijken.</li>
                <li>Via de instellingen van de DBA, beschikbaar op de homepagina,
navigatiebar en profielpagina, kan je toegang krijgen tot verschillende
functionaliteiten om je gegevens op te halen, aan te passen, te
exporteren en te downloaden.</li>
                <li>De meeste functionaliteiten van de DBA voorzien de mogelijkheid om
rechtstreeks je gegevens aan te passen of te verwijderen.</li>
            </ul>


            
            <h5 className="text-orange px-2">Welke gegevens verzamelen we en hoe?</h5>
            <p>Wanneer je gebruik maakt van de DBA verzamelen we verschillende types
gegevens.
            </p>
            <ul>
                <li>Enerzijds is er de informatie die je aanmaakt en die je ons verstrekt.</li>
                <li>Anderzijds is er informatie die we verzamelen van zodra je de DBA gebruikt.</li>        
            </ul>

            <p>
            De DBA maakt gebruik van deze informatie om je gebruikservaring te
optimaliseren en de kwaliteit en de inhoud van onze applicatie te verbeteren.
Dit betekent dat wij gebruiksinformatie en statistieken kunnen ophalen.
            </p>
            <p>
            Om gebruik te kunnen maken van de DBA moet je eerst inloggen met een
geverifieerd e-mailadres. Eens ingelogd, wordt al je informatie opgeslagen en
samengebracht onder een unieke identificatiecode (UID). Deze
identificatiecode wordt vervolgens gebruikt om je te identificeren.
            </p>
            <p>
            Hiervoor maken wij gebruik van Firebase Services. Als je de DBA gebruikt,
maak je verbinding met deze Firebase Services.
            </p>
            <p>Dit houdt in:
            </p>

            <ul>
                <li>Het authenticatiesysteem waarbij je e-mailadres en wachtwoord worden
opgeslagen in de Firebase Authentication.</li>
                <li>Je persoonlijke gegevens zoals leeftijd, geslacht en naam die worden
opgeslagen in Firebase Cloud Firestore in je persoonlijke ruimte.</li>
                <li>Je apparaat informatie.</li>
                <li>
                Notificaties: indien je gebruik maakt van de mobiele applicatie zal er
een bijkomend verbinding worden gemaakt met Firebase Cloud
Messaging, een service om Cloud notificaties te versturen.
                </li>
            </ul>



            <h5 className="text-orange px-2">Gegevens en informatie die kunnen worden bijgehouden</h5>
            <p>Informatie over je browsertype, je besturingssysteem en bijhorend netwerk
kunnen worden bijgehouden als je gebruik maakt van de DBA.
            </p>
            <p>
            Er kunnen ook gegevens worden opgehaald over de interactie van je apparaat
met deze services zoals je IP-adres, crashrapporten, systeemactiviteiten,
datum en tijd.
            </p>
            <p>
            Ook al gebruik je niet actief een functionaliteit van de DBA, en heb je de
applicatie openstaan of maak je verbinding met één van voorgaande services,
toch kunnen er gegevens worden verzameld afkomstig van je apparaat. De
DBA slaat niet continu gegevens op.
            </p>
            <p>
            We houden wel de datum bij wanneer je een vragenlijst invult. Deze
informatie wordt énkel gebruikt om je resultaten te kunnen filteren en aan jou
bekend te maken.
            </p>
            <p>
            Na het invullen van de laatste vraag van de vragenlijst, wordt je resultaat met
alle bijhorende informatie zoals je antwoorden, de afnamedatum en de
bijhorende tijd opgeslagen in je persoonlijke ruimte. 
            </p>
            <p>
            Deze informatie wordt uitdrukkelijk geanonimiseerd opgeslagen. Dit bevat je
resultaten, antwoorden, geslacht en leeftijd zoals je die ons hebt verstrekt.
Deze informatie wordt anoniem opgeslagen zonder je UID, en zonder de
mogelijkheid om rechtstreeks te kunnen achterhalen of jij de eigenaar bent
van deze informatie.
            </p>
            <p>
            Met persoonlijke ruimte bedoelen we het gedeelte in de Cloud Database
waaraan je als gebruiker van de DBA toegang toe hebt, door middel van je
unieke identificatiecode (UID), die je éénmalig wordt toegekend bij het
registeren.
            </p>


            <h5 className="text-orange px-2">Hoe verzamelen wij gegevens?</h5>
            <p>De DBA verzamelt grotendeels gegevens op basis van informatie die je ons
verstrekt. Bijvoorbeeld:
            </p>
            <ul>
                <li>
                Naam die je opgeeft bij het aanmaken van je account.
                </li>
                <li>Antwoorden op een vragenlijst, maar ook de datum en de tijdsduur.</li>
            </ul>
            <p>
            Anderzijds is er informatie die wordt verzameld door de DBA of Services die
we gebruiken, zoals: 
            </p>
            <ul>
                <li>
                Informatie over je apparaten of informatie over de browser die je
gebruikt.
                </li>
                <li>Informatie over welke functionaliteiten en wanneer je deze gebruikt.</li>
                <li>Informatie over wanneer je het laatste ingelogd was.</li>
            </ul>

            <p>
            Deze informatie kan je IP-adres of andere informatie omvatten die je locatie
kunnen bepalen.
            </p>
            <p>Informatie over je laatste inlogmoment of de datum waarop je je wachtwoord
hebt aangepast worden enkel verzameld om eventueel misbruik van je account
te kunnen detecteren. Deze informatie kan je steeds zelf raadplegen op je
profiel pagina.
            </p>
            <p>De DBA verzamelt geen gegevens zonder je voorafgaandelijke toestemming.</p>


            <h5 className="text-orange px-2">Privacy opties</h5>
            <p>
            Zoals reeds vermeld krijg je steeds de mogelijkheid om je gegevens te bekijken
en te updaten. Je kan bovendien in de instellingen van de applicatie je privacy
opties aanpassen. Je kan bijvoorbeeld Firebase Analytics aan- of uitschakelen.

            </p>
            <p>
            Eens je ingelogd bent en verbonden met internet kan je je gegevens beheren,
exporteren, verwijderen en wissen. 
            </p>
            <p>
            Je kan een kopie van de inhoud van je profielpagina exporteren. Dit bevat alle
persoonlijke informatie die we over jou beschikken in onze applicatie.
            </p>
            <p>
            Sommige toepassingen voorzien exportfuncties. Dit wil zeggen, een
functionaliteit waarbij je gegevens uit de applicatie kan exporteren naar een
document zoals PDF of CSV. Dit document kan je bijvoorbeeld in een andere
omgeving buiten de DBA gebruiken.
            </p>
            <p>
            Je kan op elk ogenblik de keuze maken om je wachtwoord aan te passen of
een nieuw wachtwoord in te stellen indien je dit vergeten bent. De
voorwaarde is wel dat je reeds toegang hebt tot het e-mailadres gelinkt aan je
account. Nadien kan je een aanvraag doen om je wachtwoord opnieuw in te
stellen. Je krijgt via een e-mailbericht een link toegestuurd om een nieuw
wachtwoord in te stellen.

            </p>

            <p>
            Je kan steeds kiezen om je account te verwijderen. Je moet wel je wachtwoord
ingeven om je account definitief te verwijderen. 
            </p>
            <p>
            Als je je account hebt verwijderd, worden je overige gegevens eveneens
verwijderd: je accountinformatie wordt verwijderd uit Firebase Authentication
en al je informatie die werd opgeslagen in je persoonlijke ruimte worden
definitief verwijderd. Als je toegang had tot een premium abonnement, verlies
je ook deze toegang.
            </p>

            <p>
            Sommige gegevens kunnen dus niet rechtstreeks worden verwijderd en
worden enkel verwijderd bij het verwijderen van je account. Sommige
gegevens bewaren we voor een langere periode indien dat nodig zou zijn voor
rechtmatige zakelijke of juridische doeleinden zoals beveiliging, financiële
administratie of fraude-en misbruikpreventie.

            </p>

            <p>
            We zorgen ervoor dat je gegevens niet toevallig, per ongeluk of kwaadwillig
worden verwijderd. Daarom moet je steeds een wachtwoord invoeren om je
account te verwijderen. Het is cruciaal om je wachtwoord veilig te bewaren,
niet te delen en regelmatig aan te passen.
            </p>

            <p>We delen geen persoonlijke gegevens met bedrijven, organisaties en
            individuen buiten de DBA, behalve als je hiertoe je toestemming hebt
            gegeven.
            </p>

            <p>
            We mogen gegevens openbaar delen over het gebruik van onze applicatie. 
            </p>

            <p>Studenten of werknemers die een e-mailadres van hun bedrijf of werkgever
            gebruiken, dienen zich ervan bewust te zijn dat de aanmaak van hun account
            kan opgemerkt worden door de domeinbeheerders.
            </p>

            
            <h5 className="text-orange px-2">Worden mijn gegevens veilig bijgehouden?</h5>
            <p>Zoals reeds vermeld maken we gebruik van Firebase Services. Deze bezitten
sterke beveiligingsfuncties die gegevens continu beschermen. Bovendien doen we er alles aan om de veiligheidsrisico’s te verbeteren en regelmatig opnieuw in te schatten. Als we risico’s zouden detecteren zullen we je meteen op de
hoogte brengen.
            </p>

            <p>
            Veiligheid binnen de DBA houdt in:
            </p>

            <ul>
                <li>Versleutelen van jouw wachtwoord en je persoonlijke gegevens</li>
                <li>
                E-mailverificatie om je account te beschermen
                </li>
                <li>
                Evalueren op regelmatige basis van de veiligheidsrisico’s
                </li>
            </ul>
            
            <h5 className="text-orange px-2">Toepasselijk recht en bevoegde rechtbanken</h5>
            <p>Door deze website te bezoeken en de DBA te gebruiken aanvaardt u dat de volledige inhoud
op deze website en de applicatie, met inbegrip van de wettelijke informatie, beheerst wordt
door het Belgisch recht en overeenkomstig het Belgisch recht moet worden geïnterpreteerd.
Bij eventuele geschillen zijn alleen de rechtbanken uit het rechtsgebied Antwerpen en Gent
bevoegd.

            </p>

        

            <div className="row pt-2 pb-2">
                <div className="col text-center">
                    <Link to="dba" className="btn btn-white">Ontdek de Digitale Burnout Applicatie © <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
            </div>

         
                            
        </div>    </div>
                     
               
      
                     </section></div>
                     
  )
}

export default Privacy