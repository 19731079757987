import React from 'react'
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import MeerInfo from './MeerInfo';

function Preventie() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        };
        scrollToTop();

    return (
        <div className="home">     
        <Helmet>
            <title>Digitale Burnout Applicatie © Burnout Preventie</title>
            <meta name="description" content="Een Mobiele Applicatie waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken. Bovendien kan je genieten van premium features gericht op burnout preventie. De Digitale Burnout Applicatie © heeft ook een unieke chatbot de 'BURNout-bot'." />
        </Helmet>  
            
            <section>
            <div className="wave-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
            </section>
            <section className="info-section">
            <div className="head-box text-center">
                        <h2 className="text-purple">Burnout Preventie</h2>
                        <div className="head-box">
                        <h6 className="text-underline-primary pb-2">
                        De wettelijke regelgeving bepaalt dat elke onderneming, klein of groot, verplicht wordt om inspanningen te leveren om risico’s zoals stress en burnout te voorkomen.
                        Ondanks het signaal van een individuele burnout nemen werkgevers meestal een afwachtende houding aan. Vermoedelijk kan een individuele burnout een knipperlicht zijn van een groter probleem in de organisatie.
                        Preventief optreden door zowel de werkgevers als de werknemers kunnen effectief bijdragen in het beperken van de verdere uitval van één of meerdere medewerkers.
                        </h6>
                        <div className="head-box">
                            <div className="row pt-5"><i className="fas fa-info-circle text-purple"></i>
                            <p>
                                
                                Op deze pagina vind je meer informatie over burnout preventie:
                                <br></br>
                                je leert onder andere hoe je een burnout kan voorkomen, en je krijgt een overzicht van handige tips &amp; tricks. 
                            </p></div> 
                            
                        </div>
                        </div>

                        
                    </div>
            </section> 
            <section>
                <div className="wave-bottom flip">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
            </section>          
            
            <section className="info-section bg-orange">             
                <div className="head-box ons-doel">
                    <h3 className="text-purple">
                        Preventie
                    </h3>                   
                    <div className="head-box pt-2 pb-2 ">                        
                    <h5 className="text-orange px-2">Burnout als een knipperlicht voor een groter problem</h5>
                        
                        <p>
                            De jongste jaren kennen de burnoutcijfers een zeer hoge vlucht. Dit heeft meteen als gevolg dat door een psycho-sociale belasting de uitval van werknemers zeer sterk is gestegen.
                            Tegelijk geven steeds meer medewerkers aan dat ze dreigen uit te vallen door bijvoorbeeld de toenemende werkdruk of de hoeveelheid werk.
                            Ook werkgevers geven aan dat medewerkers vaker het signaal geven dat ze tegen hun grenzen aanlopen, maar ze weten niet altijd hoe ze dit kunnen verhelpen.
                        </p>
                       
                        <h5 className="text-orange px-2">Burnout voorkomen</h5>
                        <p>                            
                            Burnout kan je reeds voorkomen tijdens de eerste momenten van de dag.
                            Tijdens het eerste uur van je dag is je hoofd nog quasi leeg maar je zintuigen staan al op scherp. Je kan zelf de toon voor de volgende uren van de dag zetten.
                            Je kan je wekker wel degelijk wat vroeger zetten om niet meteen in je kleren te moeten springen en vliegensvlug je huis uit te lopen.
                            Kies bewust voor een aantal ochtendrituelen en een manier om rust in je ochtend te brengen die je voor de rest van je dag kan meenemen.
                        </p>      
                        <p>
                            Ben je iemand die slechts een beperkt aantal uren slaap per nacht nodig heeft. Dit klopt niet. We hebben min of meer, acht uur slaap en rust nodig per nacht. Ook tijdens de dag is het nodig om tijdig rustpauzes in te bouwen tijdens periodes van hoge productiviteit. Na elke periode van twee uur werk is het aangewezen om 10 à 15 minuten pauze te nemen. We zijn nu eenmaal geen computers die een ganse dag door kunnen functioneren.                 
                        </p>

                        <div className="row pt-2 pb-2">
                            <div className="col text-center">
                                <Link to="/wat-is-burnout" className="btn btn-white">Meer info over burnout <i className="fas fa-arrow-circle-right"></i></Link>
                            </div>
                        </div>

                        <h5 className="text-orange px-2">Anticiperende intelligentie</h5>
                        <p>                       
                            Onze applicatie bevat een unieke, valide en betrouwbare vragenlijst om burnoutklachten te meten. De burnout applicatie kan gebruikt worden om zowel individuele diagnoses als screening in organisatie effectiever en efficiënter mogelijk te maken op een wetenschappelijke verantwoorde manier.
                        </p>
                        <p>                            
                            Artificiële intelligentie kan symptomen van burnout constateren maar kan geen diagnose stellen of een behandeling bepalen.
                            Artificiële intelligentie is een wetenschappelijke discipline, net zoals biologie of wiskunde. 
                            Precies omdat het een discipline is kan het problemen oplossen en methodes ontwikkelen voor het oplossen van problemen zoals het ontdekken van patronen bij de ontwikkeling van burnout.
                        </p>                
                        
                    </div>

                    <hr></hr>      

                    <h3 className="text-purple">
                        Tips &amp; tricks
                    </h3>    
                    <div className="head-box pt-2">  
                    <h5 className="text-fire px-2"><i className="fas fa-exclamation-triangle text-fire"></i> Alarmsignalen</h5>
                            <p>
                                Het allereerste alarmsignaal eigen aan burnout is een verandering in het gedrag van de medewerker. De aanvankelijk zeer stipte medewerker komt regelmatig te laat op het werk. Vroeger nam hij vaak het woord op vergaderingen maar keer op keer verdwijnt hij naar de achtergrond.
                            </p>
                            <ul>
                                <li>De medewerker voelt zich heel dikwijls moe, leeg en uitgeput.</li>
                                <li>De medewerker lijkt wel uitgeblust en beschikt niet meer over de nodige energie om normaal te kunnen functioneren.</li>
                            </ul>
                            <p>
                                
                                
                                
                            </p>
                    </div>
                    <hr></hr>
                    
                    <div className="row pt-2 pb-2">
                        <div className="col text-center">
                            <Link to="/burnout-en-stress" className="btn btn-white">Burnout en stress<i className="fas fa-arrow-circle-right"></i></Link>
                        </div>
                    </div>

                    <hr></hr>

                    <MeerInfo/>
                    
                  
                </div>
            </section>
        </div>
    )
}

export default Preventie;
