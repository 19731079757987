import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import './OnsDoel.css';

function OverOns() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
      scrollToTop();

    return (
        <div className='home'>
            <Helmet>
              <title>Digitale Burnout Applicatie © Ons doel</title>
              <meta name="description" content="Een Mobiele Applicatie waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken. Bovendien kan je genieten van premium features gericht op burnout preventie. De Digitale Burnout Applicatie © heeft ook een unieke chatbot de 'BURNout-bot'." />
            </Helmet>  
            <section>
                <div className="wave-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </section>
            <section className="info-section">
            <div className="head-box text-center">
                        <h2 className="text-orange">Ons doel</h2>
                        <div className="head-box">
                            <h6 className="text-underline-primary pb-2">De doelstelling van de Digitale Burnout Applicatie is gericht op het correct meten van burnout en preventief inzetten op een verdere ontwikkeling van de werkgerelateerde mentale aandoening "burnout".</h6>
                            <div className="head-box">
                            <div className="row pt-5">
                                <i className="fas fa-info-circle text-purple"></i>
                                <p>
                                    We trachten hieronder een duidelijk overzicht te geven van de verschillende doelstellingen eigen aan de ontwikkeling van onze applicatie omtrent detectie en preventie van werkgerelateerde burnout.
                                </p>
                            </div> 
                            
                        </div>
                        </div>
                    </div>
            </section> 
            <section>
                <div className="wave-bottom flip">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </section>          
          
            <section className="info-section bg-purple">             
                <div className="head-box ons-doel">
                    <h3 className="text-purple">
                        1. Streven naar een
                        <strong> professioneel advies </strong>
                        tegenover een volatiele berichtgeving
                    </h3>
                    <div className="head-box pt-2 pb-2 ">
                        <h5 className="text-orange px-2">To burn or not to burnout</h5>
                            <p className="pt-3">
                                Het populaire karakter van het woord burnout zorgt er voor dat deze term niet altijd correct wordt gebruikt. 
                            </p>
                            <p>
                            Het is van cruciaal belang dat onze gebruikers enerzijds, direct een resultaat ontvangen na het invullen van de vragenlijst. Maar anderzijds ook gebruik kunnen maken van een digitaal opvolgingssysteem met 
                            bijkomend professioneel advies en informatie omtrent de werkgerelateerde mentale aandoening "burnout".
                            </p>
                            <p>
                            Onze focus ligt daarom op het aanbieden van een gratis informatie aanbod over de ontwikkeling van burnout als een werkgerelateerde aandoening, het voorspellen ervan en idealiter het voorkomen van 
                            burnout door tijdig de alarmsignalen te (h)erkennen en burnoutklachten te meten.
                            </p>
                            <div className="row pt-2 pb-4">
                                <div className="col text-center">
                                    <Link to="/wat-is-burnout" className="btn btn-white">Wat is burnout ? <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>
                        <h5 className="text-orange px-2">Zelfdiagnose versus een professionele diagnose</h5>
                            <p className="pt-3">
                            Anderzijds blijft er het knelpunt bij zelfhulp en het gebruik van zelfbeschrijvingsvragenlijsten waarbij de 
                            oplossing voor een probleem volledig bij jezelf wordt gelegd.
                            </p>
                            <p className="pt-3">
                            Hierdoor ben je geneigd om een zelfdiagnose te stellen, wat allerminst de bedoeling kan zijn.
                            </p>
                            <p className="text-center">
                            En zeker niet bij de diagnose burnout, waar de ontwikkeling van deze werkgerelateerde mentale aandoening steeds één zaak is van twee.
                            </p>
                            <p className="text-center">
                                Werkgever én werknemer.
                                <br></br>
                                Werknemer én zijn omgeving
                                <br></br>
                                Zelfstandige én zijn klant.
                            </p>
                       
                        <h5 className="text-orange px-2">Burnout is telkens één zaak van twee!</h5>
                            <p className="pt-3">
                                De <span className="text-purple">diagnose burnout kan énkel gesteld worden door een professionele deskundige zoals een arts of een erkend psycholoog </span>die vertrouwd is met dit ziektebeeld. Temeer omdat burnout een complexe aangelegenheid is en steeds een individueel verloop kent. 
                            </p>
                            <p>
                                De diagnose kan pas echt gesteld worden wanneer het normale functioneren verstoord wordt en werken niet meer mogelijk is.
                            </p>                         
                    </div>
                    <hr></hr>
            

                    <h3 className="text-purple pt-3">
                        2. Streven naar  
                        <strong> zekerheid </strong>
                        in een wereld van onzekerheid.
                    </h3>
                    <div className="head-box pt-2 pb-2">
                        <h5 className="text-orange px-2">Burnout meten met een Applicatie</h5>
                            <p>
                                De Digitale Burnout Applicatie meet een mogelijke burnout: eenvoudig en snel.
                            </p>
                           
                            <p>
                                Na het invullen van de vragenlijst wordt je burnout niveau ook meteen vergeleken met normen en geeft dit al een eerste indicatie van een:
                            </p>  
                            <ul>
                                <li><span className="text-green">afwezigheid</span> van burnout,</li>
            
                                <li><span className="text-orange">mogelijke ontwikkeling</span> van een burnout,</li>
                                <li><span className="text-red">aanwezigheid</span> van een burnout.</li>
                            </ul>
                            <p>
                                
                                Op die manier kan je een persoonlijke, baseline vastleggen en in kaart brengen. 
                            </p>
                        <h5 className="text-orange px-2">Burnout preventief aanpakken</h5>
                            <p>
                                Om burnout zinvol en preventief aan te pakken kan je gebruik maken van onze applicatie:
                            </p>
                            <ul>
                                <li>
                                    Bij voorkeur een <span className="text-purple">wekelijkse</span>, korte meting door <span className="text-purple">12</span> vragen te beantwoorden.
                                </li>
            
                                <li>
                                    Een <span className="text-purple">tweewekelijkse</span> of een maandelijkse uitvoerige meting door <span className="text-purple">23</span> vragen te beantwoorden.
                                </li>                            
                            </ul>
                            <p>
                                Onze applicatie maakt gebruik van Anticiperende Intelligentie om een individueel uitgebreid digitaal opvolgingssyteem te kunnen aanbieden, aan de hand van bijkomende objectieve meetgegevens zoals het tijdstip en duur van invulling van de vragenlijst. 
                            </p>      
                            <div className="row pt-2 pb-4">
                                <div className="col text-center">
                                    <Link to="/preventie" className="btn btn-white">Burnout preventie <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>          
                    </div>
                    <hr></hr>

                    <h3 className="text-purple pt-3">
                        3. Streven naar  
                        <strong> kwalitatieve vereenvoudiging </strong>
                        tegenover complexiteit.
                    </h3>
                    <div className="head-box pt-2 pb-2">
                    <h5 className="text-orange px-2">Voorkom burnout en verminder je stressniveau met de applicatie</h5>
                        <p>
                            Met de Digitale Burnout Applicatie kan je je burnoutklachten meten en je burnoutscore waarnemen en opvolgen.
                        </p>
                        <p>                            
                            Op die manier krijg je een beter inzicht hoe je zelf reageert op belastende elementen in je werksituatie waardoor je overbelasting en uiteindelijk een burnout kan voorkomen.
                        </p>
                    <h5 className="text-orange px-2">Beperkte stress is geen probleem</h5>
                        <p>
                            Een beperkte hoeveelheid stress is géén probleem. Integendeel zelfs, het helpt je om met meer focus zelfs beter te presteren dan in normale omstandigheden. Maar wanneer stress langdurig optreedt en voor een chronische toestand van paraatheid aanleiding geeft, kan je leven ernstig ontregeld worden. Een chronische overbelasting kan leiden tot de ontwikkeling van een burnout.
                        </p>
                    <h5 className="text-orange px-2">GRATIS account zonder verplichtingen</h5>
                        <p>
                            Je kan een gratis account aanvragen zonder verdere verplichtingen. Het kost je niets en je persoonlijke gegevens zijn gewaarborgd door de regels eigen aan de privacy en GDPR wetgeving.
                        </p>
                        <p>
                            Nadat je de vragenlijst met de Digitale Burnout Applicatie heb ingevuld kan je concrete adviezen ontvangen om een beter zicht te krijgen op het ontstaan van een burnout en inzicht te bekomen om stress te vermijden en op te lossen.
                            <br></br>
                            Door opeenvolgende metingen uit te voeren zal je zelf te zien krijgen of er een verbetering optreedt waardoor er een positief effect in je gedrag ontstaat.
                        </p>
                    <h5 className="text-orange px-2">Nood aan professionele begeleiding</h5>
                        <p>
                            Bij langdurige hoge waarden is het sterk aangewezen om contact op te nemen met een deskundige en je professioneel te laten begeleiden. 
                        </p>
                    </div>
                    <hr></hr>

                    <h3 className="text-purple pt-3">
                        4. Streven naar  
                        <strong> duidelijkheid </strong>
                        tegenover dubbelzinnigheid of ambiguïteit.
                    </h3>
                    <div className="head-box pt-2 pb-2">
                    <h5 className="text-orange px-2">Wetenschappelijke basis voor de burnout applicatie</h5>
                        <p>
                            De wetenschappelijke basis voor de burnoutapp werd ontwikkeld door Prof. Dr. Wilmar Schaufeli,  Prof. Dr. Hans De Witte en Steffie Desart.
                            <br></br>
                            Aangezien er enkel een schriftelijke versie van de Burnout Assessment Tool bestaat, ontstond de noodzaak om een handige applicatie te ontwikkelen.
                        </p>
                    <h5 className="text-orange px-2">Duidelijke weergave van de resultaten</h5>
                        <p>
                            De burnoutapp geeft met duidelijke grafieken aan, hoe de ontwikkeling van een mogelijke burnout per week en per maand  kan verlopen. 
                            Op basis van de resultaten kan je als deelnemer van de burnout  applicatie advies ontvangen over hoe je met stress en de ontwikkeling van een burnout kan omgaan. Hoe meer meetresultaten er vastgelegd worden, hoe gerichter de adviezen zijn.
                        </p>
                    <h5 className="text-orange px-2">Respect voor de persoonlijke gegevens</h5>
                        <p>
                            Het respecteren van de privacy is belangrijk wanneer het om het vastleggen van persoonlijke gegevens gaat. Een werkgever kan op geen enkele manier vertrouwelijke gegevens zoals het individuele niveau van een burnout ontwikkeling bij de werknemers nakijken.
                        </p>
                    <h5 className="text-orange px-2">Burnout op niveau van de organisatie</h5>
                        <p>
                            Van zodra meer dan 30 mensen deelnemen, is het wel aangewezen om zicht te krijgen op het burnoutniveau van een afdeling om na te gaan of een bedrijfsbeslissing wel een positieve invloed heeft op de gezondheid van haar medewerkers.
                        </p>
                    </div>
                    <hr></hr>

                    <h3 className="text-purple pt-3">
                        5. Streven naar  
                        <strong> snelle </strong>
                        tegenover trage interventies of acties.
                    </h3>
                    <div className="head-box pt-2 pb-2">
                    <h5 className="text-orange px-2">Burnout preventie</h5>
                        <p>
                            Mensen zijn meestal niet snel geneigd om de stap te zetten naar een psychologische of medische begeleiding wanneer er zich symptomen voordoen zoals: angst, paniek, stress of chronische vermoeidheid, .. 
                            <br></br>
                            Door het aanbieden van een klantvriendelijke en vooral een laagdrempelige applicatie hopen we zoveel mogelijk mensen te kunnen bereiken om de mate van burnout correct te meten.
                        </p>                  

                    <h5 className="text-orange px-2">Preventief handelen door persoonlijk leiderschap</h5>
                        <p>
                            We zijn blij wanneer mensen worden getriggerd om onze applicatie daadwerkelijk te gebruiken en de stap durven zetten om samen mét een deskundige een verdere ontwikkeling van de mentale aandoening burnout te voorkomen.
                            <br></br>
                            Een individuele begeleiding die geen rekening houdt met de werkcontext zal niet meteen een verbetering inhouden.
                        </p>
                    <h5 className="text-orange px-2">VERTROUW IN PREVENTIE</h5>
                        <p>
                            Betrekken we de werksituatie mee in de begeleiding dan bekomen we niet alleen een snellere werkhervatting maar ook een vermindering van de klachten eigen aan burnout.
                            <br></br>
                            Tijdens de lange periode die meestal aan een burnout voorafgaat kan men wel degelijk preventief ingrijpen en de medewerker ondersteunen.
                        </p>
                    <h5 className="text-orange px-2">Voorkomen is nog steeds beter dan genezen</h5>
                        <p>
                            Wacht men tot het laatste stadium van een burnout, dan kan een behandeling en herstel tot TWEE jaar duren.
                        </p>
                    </div>
                    <hr></hr>
                    <div className="row pt-2 pb-4">
                        <div className="col text-center">
                            <Link to="/burnout-app" className="btn btn-white">Ontdek onze Applicatie <i className="fas fa-arrow-circle-right"></i></Link>
                        </div>
                    </div>

                    
                    
                               
                                
                </div>
                     
               
      
            </section>
            
        </div>
    )
}

export default OverOns
