import React from 'react'
import { Helmet } from 'react-helmet';

function Chatbot() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
      scrollToTop();
      
    return (
        <div className="home bg-dark-grey">
            <Helmet>
              <title>Digitale Burnout Applicatie © BURNout-bot</title>
              <meta name="description" content="Een Mobiele Applicatie waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken. Bovendien kan je genieten van premium features gericht op burnout preventie. De Digitale Burnout Applicatie © heeft ook een unieke chatbot de 'BURNout-bot'." />
            </Helmet>
            <div className="blob">
                <div className="blob-content item-center ">                     
                    <h3 className="text-underline-white pb-4">ONTDEK ONZE <br></br> BURNout-bot</h3>
                    <p className="pt-5">Heb je een vraag of ben je op zoek naar specifieke informatie in verband met burnout?</p>
                
                    <p>Start een gesprek met onze BURNout-bot!</p> 
                    <i className="fas fa-arrow-circle-down py-5"></i>

                    <p>In de Digitale Burnout Applicatie kan je een dialoog starten met onze unieke BURNout-bot. Bovendien kan je gebruik maken van je microfoon om je vraag in te spreken.</p> 

                    <img src="images/gesprekChatbot.png" className="app-image" alt="DBA© Chatbot BURNout-bot"/>
                </div>
            </div>
            
        </div>
    )
}

export default Chatbot
