import React from 'react'
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import MeerInfo from './MeerInfo';

function Stress() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        };
        scrollToTop();

    return (
        <div className='home'> 
            <Helmet>
              <title>Digitale Burnout Applicatie © Burnout en stress</title>
              <meta name="description" content="Een Mobiele Applicatie waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken. Bovendien kan je genieten van premium features gericht op burnout preventie. De Digitale Burnout Applicatie © heeft ook een unieke chatbot de 'BURNout-bot'." />
            </Helmet>  
            <section>
            <div className="wave-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
            </section>
            <section className="info-section">
            <div className="head-box text-center">
                        <h2 className="text-purple">Burnout en stress</h2>
                        <div className="head-box">
                        <h6 className="text-underline-primary pb-2">
                            Stress en burnout worden heel vaak door elkaar gebruikt. Nochtans is er een belangrijk verschil tussen beide termen. 
                        </h6>
                        <div className="head-box">
                            <div className="row pt-5"><i className="fas fa-info-circle text-purple"></i>
                            <p>
                                
                                Op deze pagina vind je meer informatie over stress:
                                <br></br>
                                je leert wat de kracht is van stress, en je leert het onderscheid tussen burnout en stress.
                            </p></div> 
                            
                        </div>
                        </div>

                        
                    </div>
            </section> 
            <section>
                <div className="wave-bottom flip">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
            </section>          
            
            <section className="info-section bg-orange">             
                <div className="head-box ons-doel">
                    <h3 className="text-purple">
                        Burnout en stress
                    </h3>                   
                    <div className="head-box pt-2 pb-2 ">    
                    <p>
                        Burnout en stress hebben gelijkaardige symptomen maar stress is doorgaans een toestand van korte duur. Bij langdurige of chronische stress kan het aanleiding geven tot burnout.
                        De klachten eigen aan burnout kan je meten met de burnoutklachten vragenlijst en een applicatie.
                    </p>
                    
                    <h5 className="text-orange px-2">Stress is absoluut normaal</h5>
                        
                        <p>
                            We kunnen mensen leren dat ze niet bang hoeven te zijn voor stress. Ze kunnen wel degelijk vaardigheden leren om de eerste alarmsignalen van stress te herkennen, daar constructief mee om te gaan waardoor zij zich niet vastrijden.
                            <br></br>
                            Stress is ook een kwestie van omgaan met schakeringen. Het gaat er precies om deze nuances correct te managen mét persoonlijk leiderschap.
                            Stress is absoluut normaal. Toegegeven, de ene persoon ervaart stress als een unieke kans om tot excellente prestaties te komen, de andere ervaart stress als een nachtmerrie. Maar er is meer, .. Stress-signalen kunnen we opvatten als nieuwe uitdagingen om het anders en beter te doen. 
                        </p>
                       
                        <h5 className="text-orange px-2">Stress is een kracht</h5>
                        <p>                            
                            Als we géén dreiging ervaren zijn we niet geneigd om iets te veranderen, en lopen we het risico om te stagneren of ter plaatse te trappelen. 
                            Hierdoor spreken we onze vaardigheden niet meer aan.
                            Stress is wel degelijk een teken van actie, verbetering en vooruitgang om effectiever en efficiënter te handelen.
                        </p>      
                                
                        
                    </div>

                    <hr></hr>      

                    <h3 className="text-purple">
                        Burnout &amp; depressie
                    </h3>    
                    <div className="head-box pt-2 pb-2 ">  
                            <p>
                                Burnout en depressie zijn totaal verschillende ziekte aandoeningen. Burnout is werkgerelateerd en heeft te maken met een energiestoornis.
                                Depressie is een stemmingsziekte en heeft niet alleen met het werk te maken maar ook met andere elementen.
                                Onze applicatie is uitsluitend bedoelt om klachten eigen aan burnout te meten.
                            </p>
                    </div>

                    <hr></hr>
                    
                    <div className="row pt-2 pb-2">
                        <div className="col text-center">
                            <Link to="/burnout-app" className="btn btn-white">Ontdek onze applicatie<i className="fas fa-arrow-circle-right"></i></Link>
                        </div>
                    </div>

                    <hr></hr>

                    <MeerInfo/>
                </div>

                <br></br>
            </section>
        </div>
    )
}

export default Stress;
