import './App.css';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import Home from './pages/Home';
import BurnoutApp from './pages/BurnoutApp';
import Chatbot from './pages/Chatbot';
import Navbar from './Navbar';
import OnsDoel from './pages/OnsDoel';
import Footer from './Footer';
import Burnout from './pages/burnout/Burnout';
import Preventie from './pages/burnout/Preventie';
import Stress from './pages/burnout/Stress';
import Privacy from './pages/Privacy';
import PrivacyDBA from './pages/PrivacyDBA';
import Premium from './pages/Premium';
import Disclaimer from './pages/Disclaimer';
import CookieConsent from "react-cookie-consent";

import { initializeApp } from 'firebase/app';
import { initializeAnalytics } from '@firebase/analytics';
import { getPerformance } from '@firebase/performance';
function App() {
  var firebaseConfig = {
    apiKey: "AIzaSyCt6Bz6jA3W0GQWKSHbI_MOZysJBQZ7ZtU",
    authDomain: "burnout-app-3c9fc.firebaseapp.com",
    projectId: "burnout-app-3c9fc",
    storageBucket: "burnout-app-3c9fc.appspot.com",
    messagingSenderId: "352625732266",
    appId: "1:352625732266:web:2a0189fc9e29cd139d3ed3",
    measurementId: "G-K1FLV9Z9J9"
  };
 
  return (

    <>
      <Router> 
        <Navbar/>
        <Switch>  

          <Route path='/' exact component = {Home}/>
          <Route path='/burnout-app' component = {BurnoutApp}/>
          <Route path='/chatbot' component = {Chatbot}/>
          <Route path='/ons-doel' component = {OnsDoel}/>

          <Route path='/wat-is-burnout' component = {Burnout}/>
          <Route path='/preventie' component = {Preventie}/>
          <Route path='/burnout-en-stress' component = {Stress}/>
          <Route path='/privacybeleid' component = {Privacy}/>
          <Route path='/privacybeleid-dba' component = {PrivacyDBA}/>
          <Route path='/disclaimer' component = {Disclaimer}/>
          <Route path='/dba-premium' component = {Premium}/>

      
          {/* ROUTE TO DBA */}
          <Route path='/dba' component={() => { 
              window.location.replace('https://digitaleburnoutapp.be'); 
              return null;
          }}/>

         
        </Switch>
        <Footer/>
       
        <CookieConsent        
          location="bottom"
          declineButtonText ="Weigeren"
          buttonText="Aanvaarden"          
          cookieName="Google Analytics"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
          expires={150}
          enableDeclineButton
          onDecline={() => {
            window['ga-disable-G-K1FLV9Z9J9'] = true;
          }}
          onAccept={() => {
             // Initialize Firebase
            const app = initializeApp(firebaseConfig);
            // Init Analytics & Performance
            initializeAnalytics(app);
            getPerformance(app);
          }}
          
        >
          Maak kennis met de allereerste applicatie die burnoutklachten op een wetenschappelijk verantwoorde manier kan meten. Onze website maakt gebruik van Cookies om de gebruikerservaring te verbeteren. Ben je akkoord om Google Analytics in te schakelen op deze website?<br></br>
          <span style={{ fontSize: "14px" }}>Voor meer informatie kan je ons <Link to="/privacybeleid">Privacybeleid</Link> raadplegen.</span><br></br><Link to="/disclaimer">Disclaimer</Link>.
        </CookieConsent>
      </Router>
     </>
   
  );
}

export default App;


