import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Premium() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
      scrollToTop();
  return (
    <div className='home'>
        <Helmet>
            <title>Digitale Burnout Applicatie © Premium</title>
            <meta name="description" content="Ontdek ons Premium aanbod en geniet van diverse functionaliteiten gericht op burnout preventie." />
        </Helmet>     
        <div className="wave-bottom">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                </svg>
            </div>        
        <section className="item-center ">   
            <h1 className="text-purple">Ontdek weldra PREMIUM</h1>   
            <img src="/images/premium-2.png" alt="Digitale Burnout Applicatie" className="app-image p-5"/>
            
            <h1 className="text-purple">Kies binnenkort jouw PREMIUM abonnement op maat!</h1>
            <img src="/images/neemPremium.png" alt="Digitale Burnout Applicatie" className="app-image p-5"/>

            <h1 className="text-orange pt-5">PREMIUM SILVER</h1>   
            <img src="/images/silver.png" alt="PREMIUM SILVER" className="app-image p-5"/>
            <ul>                
                <li>Plan je EVENTS en je ACTIVITETEN in.</li>
                <li>Maak en beheer je eigen ACTIVITETEN.</li>
                <li>Exporteer je kalender.</li>
            </ul>

            <h1 className="text-orange pt-5">PREMIUM GOLD</h1>   
            <img src="/images/gold.png" alt="PREMIUM GOLD" className="app-image p-5"/>
            <ul>                
                <li>Krijg toegang tot je DIGITAAL DAGBOEK.</li>
                <li>Ontgrendel je FRUSTRATIE DAGBOEK.</li>
                <li>Maak je eigen NOTIFICATIES</li>
                <li>Beheer je NOTIFICATIECENTRUM</li>
            </ul>
            <h1 className="text-orange pt-5">PREMIUM PLATINUM</h1>   
            <img src="/images/platinum.png" alt="PREMIUM PLATINUM" className="app-image p-5"/>
            
            <ul>                
                <li>Krijg toegang tot modules gericht op burnout preventie.</li>
                <li>Ontgrendel ACTEPLANNEN, VRAGENLIJSTEN en EXTRA MODULES gericht op zelfinzicht.</li>
                <li>Krijg toegang tot onze BURNout-bot!</li>
                <li>Geniet van alle PREMIUM features.</li>
            </ul>
            <h1 className="text-purple pt-5">Ontgrendel weldra je PREMIUM features</h1>
            
            <img src="/images/premium.png" alt="Digitale Burnout Applicatie" className="app-image p-5"/>
            <Link to="/dba" className="btn btn-white mb-4">OPEN DE WEBAPPLICATIE<i className="fas fa-arrow-circle-right"></i></Link>      
            {/* <img src="images/qr.png" className="max-img-w" alt="DBA© is GRATIS voor iedereen beschikbaar."/> */}
            {/* {/* <img src="/images/premiums.png" alt="Digitale Burnout Applicatie" className="app-image p-5"/>  */}
  
        </section>  
    </div>
  )
}

export default Premium
