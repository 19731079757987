import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Privacy() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  scrollToTop();
  return (
    <div className='home'>
    <section className=" info-section bg-orange">             
    <div className="head-box ons-doel">
    <Helmet>
              <title>Privacybeleid</title>
              <meta name="description" content="Een Mobiele Applicatie waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken. Bovendien kan je genieten van premium features gericht op burnout preventie. De Digitale Burnout Applicatie © heeft ook een unieke chatbot de 'BURNout-bot'." />
            </Helmet>  
        <h3 className="text-purple text-center">
        PRIVACYBELEID<br></br>van de website burnoutapp.be ©
        </h3>                   
        <div className="head-box pt-2 pb-2 "> 
            <p>
            In dit Privacy beleid geven we een antwoord op de volgende vragen:
            </p>
            <ul>
                <li>Wat gebeurt er met mijn gegevens</li>
                <li>Hoe worden mijn gegevens ingezameld en verwerkt?</li>
                <li>Kan ik mijn privacy voorkeuren aanpassen?</li>
            </ul>
            <p>
            Indien je een vraag of een opmerking hebt, vernemen wij dit graag via onze email. info@burnoutapp.be
            </p>
            <h5 className="text-orange px-2">Wat is een Privacy beleid?</h5>
            <p>
            Een document dat bepaalt hoe de persoonsgegevens van gebruikers verzameld en verwerkt worden.
            </p>   

            <h5 className="text-orange px-2">Wat zijn Persoonsgegevens?</h5>
            <p>
            Dit zijn alle gegevens waarmee een internetgebruiker kan worden geïdentificeerd. Voorbeelden van Persoonsgegevens zijn: je voornaam, familienaam, leeftijd, adres, e-mailadres, locatie, …
            </p>   

            <p>
            DS Consult BV verwerkt persoonsgegevens overeenkomstig deze privacyverklaring. Voor verdere informatie, vragen of opmerkingen omtrent ons privacy beleid, kunt u terecht bij inconcreto@skynet.be.
            </p>
                       
            <h5 className="text-orange px-2">Verwerkingsdoeleinden</h5>
            <p>
            DS Consult BV verzamelt en verwerkt persoonsgegevens voor o.a. administratie, opvolgen van bestellingen/leveringen, facturatie, opvolgen van de solvabiliteit, profilering en het verzenden van marketing en gepersonaliseerde reclame.
            </p> 

            <h5 className="text-orange px-2">Rechtsgronden van de verwerking</h5>
            <p>
            Persoonsgegevens worden verwerkt op basis van toestemming, noodzakelijk voor de uitvoering van een overeenkomst, noodzakelijk om te voldoen aan een wettelijke verplichting, noodzakelijk voor de behartiging van onze gerechtvaardigde belang om te ondernemen. Elke persoon heeft steeds het recht om de gegeven toestemming terug in te trekken.
            </p> 
            
            <h5 className="text-orange px-2">Worden mijn Persoonsgegevens ingezameld op deze website?</h5>
            <p>
            Nee. We vragen geen Persoonsgegevens op. Er kunnen wel, indien je expliciet toegang hebt gegeven, gebruikersinformaties worden verzameld. Maar deze bevatten geen Persoonsgegevens.
            </p>  
            
            <h5 className="text-orange px-2">Hoe worden gebruikersinformaties ingezameld?</h5>
            <p>
            Onze website maakt gebruik van Google Analytics om de gebruikerservaring te verbeteren. Indien je akkoord gaat om Google Analytics in te schakelen, zal er een Cookie worden geïnstalleerd op je toestel. Deze geeft de mogelijkheid om je te onderscheiden van andere gebruikers, zo kan Google Analytics achterhalen hoeveel actieve gebruikers er op onze website zijn.
            </p>  

            <h5 className="text-orange px-2">Overmaken aan derden</h5>
            <p>
            Indien dit noodzakelijk is ter verwezenlijking van de vooropgestelde doeleinden, zullen de persoonsgegevens gedeeld worden met derden binnen de Europese Economische Ruimte die rechtstreeks of onrechtstreeks met DS Consult BV verbonden zijn of met enige andere partner van DS Consult BV.
            </p>   
            <p>
            DS Consult BV garandeert dat deze ontvangers de nodige technische en organisatorische maatregelen zullen nemen ter bescherming van de persoonsgegevens.
            </p>
            <h5 className="text-orange px-2">Bewaarperiode</h5>
            <p>
            De persoonsgegevens zullen worden bewaard gedurende de termijn die noodzakelijk is om aan de wettelijke vereisten te voldoen (onder andere op het gebied van boekhouding).
            </p>
            <h5 className="text-orange px-2">Recht van inzage, verbetering, wissing, beperking, bezwaar en overdraagbaarheid van persoonsgegevens</h5>
            <p>
            Elke persoon heeft te allen tijde recht op inzage van zijn persoonsgegevens en kan ze (laten) verbeteren indien ze onjuist of onvolledig zijn, ze laten verwijderen, de verwerking ervan laten beperken en bezwaar te maken tegen de verwerking van hem betreffende persoonsgegevens.
            </p>
            <p>
            Bovendien, heeft elke persoon het recht om een kopie (in een gestructureerde, gangbare en machinaal leesbare vorm) van zijn persoonsgegevens te bekomen en de persoonsgegevens te laten doorsturen naar derden.
            </p>
            <p>
            Teneinde bovenvermelde rechten uit te oefenen, wordt elke persoon gevraagd om een e-mail te verzenden naar het volgende e-mailadres: inconcreto@skynet.be.
            </p>

            <h5 className="text-orange px-2">Direct marketing</h5>
            <p>
            Elke persoon heeft het recht zich kosteloos te verzetten tegen elke verwerking van zijn persoonsgegevens met het oog op direct marketing.
            </p>
            <h5 className="text-orange px-2">Klacht</h5>
            <p>
            EElke persoon beschikt over het recht om een klacht in te dienen bij de Commissie voor de Bescherming van de Persoonlijke Levenssfeer (Drukpersstraat 35, 1000 Brussel - commission@privacycommission.be).
            </p>            
            
            <br></br>

            <h5 className="text-orange px-2">Wat zijn Cookies?</h5>
            <p>
            Cookies zijn kleine tekstbestanden die door websites op je toestel worden geplaatst. Dergelijke cookies kunnen verschillende doeleinden hebben:
            </p>
            <ul>
                <li>Technische cookies: bijvoorbeeld voor taalinstellingen</li>
                <li>Sessiecookies: tijdelijke cookies</li>
                <li>Tracking cookies: cookies die je gedrag op de website gaan volgen en bijhouden</li>
            </ul>  
            <p>
            Een cookie bevat een unieke code die toelaat om uw browser te herkennen gedurende uw bezoek(en) aan deze website.
            </p>
            <p>
            Cookies kunnen geplaatst worden door de server van de website die u bezoekt of door een partner, waarmee deze website samenwerkt. De server van de website kan alleen de cookies lezen die het zelf geplaatst heeft. Hij heeft geen toegang tot andere informatie die zich op uw computer of mobiel apparaat bevindt.
            </p>
            <p>
            De inhoud van een cookie bestaat meestal uit de naam van de server die de cookie geplaatst heeft, een vervaldatum en een unieke cijfercode. Cookies maken de interactie tussen de bezoekers en de website over het algemeen gemakkelijker en sneller en helpen de bezoekers te navigeren tussen de verschillende onderdelen van de website. Cookies kunnen ook gebruikt worden om de inhoud van de website te personaliseren aan de gebruiker.
            </p>

            <h5 className="text-orange px-2">Gebruikt deze website Cookies?</h5>
            <p>
            Ja, deze website maakt gebruik Third party cookies:
            </p>
            <p>
            Deze website maakt gebruik van verschillende Third-party cookies van Google.
            <br></br>
            Google: (https://www.google.com/policies/privacy/)
            </p>
            <p>
            Wij maken enkel gebruik van de Google Analytics Cookie genaamd “_ga”. Deze Cookie biedt de mogelijkheid om de ene gebruiker van de andere te kunnen onderscheiden.
            <br></br>
            Een cookie wordt meestal voor een lange tijd opgeslagen op je toestel, dit is afhankelijk van het type cookie.
            Deze Cookie blijft 2 jaar actief en dit voor elke website waarvoor Google Analytics is geïmplementeerd en andere services van Google die diezelfde Cookie gebruiken.
            </p>
            <h5 className="text-orange px-2">Hoe werkt Google Analytics en kan ik het uitschakelen?</h5>
            <p>         
            Het is niet zo dat als je Google Analytics op één website of applicatie toelaat dat je dan overal wordt getraceerd. Dit is niet mogelijk! Elke _ga-cookie is uniek aan een applicatie en kan daarom niet worden gebruikt om een gebruiker te volgen op verschillende websites of applicaties.
            Google Analytics zal enkel worden ingeschakeld als de gebruiker hiervoor expliciet toegang aan geeft.
            </p>
            <h5 className="text-orange px-2">BEHEER VAN COOKIES</h5>
            <p>         
            Via uw browserinstellingen kunt u de installatie van cookies weigeren. Het uitschakelen van cookies kan betekenen dat U van bepaalde functionaliteiten van de website geen gebruik kunt maken. U kunt ook op elk moment de reeds geïnstalleerde cookies verwijderen van uw computer of mobiel apparaat.
            </p>

            <h5 className="text-fire px-2"><i className="fas fa-exclamation-triangle text-fire"></i> BELANGRIJK</h5>
            <p>
                Deze website "burnoutapp.be" © promoot een MOBIELE als een WEB Applicatie genaamd "Digitale Burnout Applicatie" ©.
                Het Privacybeleid van de Digitale Burnout Applicatie © is niet dezelfde als voor deze website.
                Je kan, door hieronder te drukken, het Privacybeleid van de Digitale Burnout Applicatie © bekijken.
            </p>

            <div className="row pt-2 pb-2">
                <div className="col text-center">
                    <Link to="/privacybeleid-dba" className="btn btn-white">Privacybeleid van de Digitale Burnout Applicatie © <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
            </div>

         
                            
        </div>    </div>
                     
               
      
                     </section></div>
                     
  )
}

export default Privacy