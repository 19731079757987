import React from 'react'
import { Link } from 'react-router-dom';

function Disclaimer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  scrollToTop();
  return (
    <div className='home'>
        <section className=" info-section bg-orange">             
            <div className="head-box ons-doel">
                <h3 className="text-purple text-center">
                DISCLAIMER<br></br>voor burnoutapp.be ©
                </h3>                   
                <div className="head-box"> 
                    <p>
                    Deze website is eigendom van DS Consult BV
                    </p>
                    <ul>
                        <li>Contactpersoon: Stefaan Devogelaere</li>
                        <li>Adres: Beatrijslaan 4 bus 15, 2000 Antwerpen, België</li>
                        <li>Telefoon: +32 478 54 90 08</li>
                        <li>E-mail: inconcreto@skynet.be</li>
                        <li>Ondernemingsnummer: BE 0437.927.581</li>
                    </ul>
                    <p>
                    Door de toegang tot en het gebruik van de website verklaart u zich uitdrukkelijk akkoord met de volgende algemene voorwaarden.
                    </p>
                    <h5 className="text-orange px-2">Intellectuele eigendomsrechten</h5>
                    <p>
                    De inhoud van deze site, met inbegrip van de merken, logo’s, tekeningen, data, product- of bedrijfsnamen, teksten, beelden e.d. zijn beschermd door intellectuele rechten en behoren toe aan DS Consult BV of rechthoudende derden.           
                    </p>     
                    
                    <h5 className="text-orange px-2">Beperking van aansprakelijkheid</h5>
                    <p>
                    De informatie op de website is van algemene aard. De informatie is niet aangepast aan persoonlijke of specifieke omstandigheden, en kan dus niet als een persoonlijk, professioneel of juridisch advies aan de gebruiker worden beschouwd.
                    </p>   
                    <p>
                    DS Consult BV levert grote inspanningen opdat de ter beschikking gestelde informatie volledig, juist, nauwkeurig en bijgewerkt zou zijn. Ondanks deze inspanningen kunnen onjuistheden zich voordoen in de ter beschikking gestelde informatie. Indien de verstrekte informatie onjuistheden zou bevatten of indien bepaalde informatie op of via de site onbeschikbaar zou zijn, zal DS Consult BV de grootst mogelijke inspanning leveren om dit zo snel mogelijk recht te zetten.
                    </p>
                    <p>
                    DS Consult BV kan evenwel niet aansprakelijk worden gesteld voor rechtstreekse of onrechtstreekse schade die ontstaat uit het gebruik van de informatie op deze site. Indien u onjuistheden zou vaststellen in de informatie die via de site ter beschikking wordt gesteld, kan u de beheerder van de site contacteren.
                    </p>
                    <p>
                    De inhoud van de site kan te allen tijde zonder aankondiging of kennisgeving aangepast, gewijzigd of aangevuld worden.
                    </p>
                    <p>
                    DS Consult BV geeft geen garanties voor de goede werking van de website en kan op geen enkele wijze aansprakelijk gehouden worden voor een slechte werking of tijdelijke (on)beschikbaarheid van de website of voor enige vorm van schade, rechtstreekse of onrechtstreekse, die zou voortvloeien uit de toegang tot of het gebruik van de website.
                    </p>
                    <p>
                    DS Consult BV kan in geen geval tegenover wie dan ook, op directe of indirecte, bijzondere of andere wijze aansprakelijk worden gesteld voor schade te wijten aan het gebruik van deze site of van een andere, inzonderheid als gevolg van links of hyperlinks, met inbegrip, zonder beperking, van alle verliezen, werkonderbrekingen, beschadiging van programma's of andere gegevens op het computersysteem, van apparatuur, programmatuur of andere van de gebruiker.
                    </p>
                    <h5 className="text-orange px-2">Worden mijn Persoonsgegevens ingezameld op deze website?</h5>
                    <p>
                    Nee. We vragen geen Persoonsgegevens op. Er kunnen wel, indien je expliciet toegang hebt gegeven, gebruikersinformaties worden verzameld. Maar deze bevatten geen Persoonsgegevens.
                    </p>
                    <p>
                    De website kan hyperlinks bevatten naar websites of pagina's van derden, of daar onrechtstreeks naar verwijzen. Het plaatsen van links naar deze websites of pagina’s impliceert op geen enkele wijze een impliciete goedkeuring van de inhoud ervan.
                    </p>  
                    <p>
                    DS Consult BV verklaart uitdrukkelijk dat zij geen zeggenschap heeft over de inhoud of over andere kenmerken van deze websites en kan in geen geval aansprakelijk gehouden worden voor de inhoud of de kenmerken ervan of voor enige andere vorm van schade door het gebruik ervan.
                    </p>

                    <h5 className="text-orange px-2">Toepasselijk recht en bevoegde rechtbanken.</h5>
                    <p>
                    Het Belgisch recht is van toepassing op deze website. In geval van een geschil is enkel het gerechtelijk arrondissement, waaronder de maatschappelijke zetel van DS Consult BV valt, bevoegd.
                    </p>         

                    <div className="row pt-2 pb-2">
                        <div className="col text-center">
                            <Link to="/privacybeleid" className="btn btn-white">Lees ons Privacybeleid <i className="fas fa-arrow-circle-right"></i></Link>
                        </div>
                    </div>                               
                </div>    
            </div>
        </section>
    </div>
                     
  )
}

export default Disclaimer