import React from 'react'
import { Link } from 'react-router-dom';

function MeerInfo() {
    return (
        <div>
            <div className="head-box text-center"> 
             <h3 className="text-purple">
                        Meer informatie bekomen
                    </h3>       
                    <div className="head-box"> 
                        <h6>Zit je met een specifieke vraag of wens je extra informatie?</h6>
                        <h6> Aarzel dan zeker niet om contact met ons te nemen!</h6>
                    </div>
            </div>
            

            <div className="row pb-2">
                <div className="col text-center">
                    <Link to="/FAQ" className="btn btn-white">FAQ <i className="fas fa-arrow-circle-right orange"></i></Link>
                </div>  
            </div>
            <div className="row pb-2">
                <div className="col text-center">
                    <Link to="/chatbot" className="btn btn-white">Onze chatbot <i className="fas fa-arrow-circle-right orange"></i></Link>
                </div>               
            </div>
            <div className="row pb-4">
                <div className="col text-center">
                    <a href="mailto:info@burnoutapp.be" className="btn btn-white"><i className="far fa-envelope"></i> info@burnoutapp.be </a>
                </div>
            </div>

          
                
        </div>
    )
}

export default MeerInfo;
