import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div> 
            <footer className="nb-footer pt-4">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="about pt-2">
                      <img src="images/logo.png" className="max-img-w center-block" alt="DE DGIGITALE BURNOUT APPLICATIE ©"/>
                      <p>DIGITALE BURNOUT APPLICATIE ©</p>
                      <p>Een MOBIELE en WEBAPPLICATIE waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken. Bovendien kan je genieten van PREMIUM features gericht op burnout preventie. De Digitale Burnout Applicatie © heeft ook een unieke chatbot, de 'BURNout-bot'.</p>
                      <div className="social-media">
                        <ul className="list-inline">
                          {/* <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                          <li><a href="#"><i className="fa fa-twitter"></i></a></li> */}
                          <li><a href="mailto:info@burnoutapp.be"><i className="far fa-envelope"></i></a></li>
                          {/* <li><a href="#"><i className="fa fa-linkedin"></i></a></li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="footer-info-single">
                      <h2 className="title"><i className="far fa-envelope"></i> Email</h2>
                      <ul className="list-unstyled">
                        
                        <li><a href="mailto:info@burnoutapp.be"> info@burnoutapp.be</a></li>
                        <br></br>
                        <li><Link to="/privacybeleid"><i className="fa fa-angle-double-right"></i>Privacybeleid</Link></li>      
                        <li><Link to="/disclaimer"><i className="fa fa-angle-double-right"></i>Disclaimer</Link></li>      
                        
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="footer-info-single">
                      <h2 className="title">Over ons</h2>
                      <ul className="list-unstyled">
                        <li><Link to="/"><i className="fa fa-angle-double-right"></i>Home</Link></li>
                        <li><Link to="/burnout-app"><i className="fa fa-angle-double-right"></i>Onze Applicatie</Link></li>
                        <li><Link to="/ons-doel"><i className="fa fa-angle-double-right"></i>Ons doel</Link></li>
                        <li><Link to="/chatbot"><i className="fa fa-angle-double-right"></i>BURNout-bot</Link></li>
                        
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="footer-info-single">
                      <h2 className="title">Over burnout</h2>
                      <ul className="list-unstyled">
                        <li><Link to="/wat-is-burnout"><i className="fa fa-angle-double-right"></i>Wat is burnout?</Link></li>
                        <li><Link to="/preventie"><i className="fa fa-angle-double-right"></i>Burnout preventie</Link></li>
                        <li><Link to="/burnout-en-stress"><i className="fa fa-angle-double-right"></i>Burnout en stress</Link></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="footer-info-single">
  
                      <h2 className="title">DIGITALE BURNOUT APPLICATIE ©</h2>
                      <p>Ga nu meteen van start met de DBA ©</p>
                      <ul className="list-unstyled py-4">  
                      <li><Link to="/privacybeleid-dba"><i className="fa fa-angle-double-right"></i>Privacybeleid DIGITALE BURNOUT APPLICATIE</Link></li>      
                      </ul> <div className="row">
                          <div className="col text-center p-3">
                              <Link to="/dba">
                              <img src="images/footer.png" className="max-img-w center-block" alt="DE DGIGITALE BURNOUT APPLICATIE ©"/>
                              </Link>
                          </div>
                      </div>  
                     
                    </div>
                  </div>

                </div>
              </div>

              <section className="copyright">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                    <p>Copyright © 2021-2022. www.burnoutapp.be  - Digitale Burnout Applicatie - DBA © .</p>
                    </div>
                    
                  </div>
                </div>
              </section>
            </footer>            
        </div>
    )
}

export default Footer
