import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Home() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
      scrollToTop();
      
    return (
        <div className="home">
            <Helmet>
              <title>Digitale Burnout Applicatie © Home</title>
              <meta  name="description" content="Mobiele Applicatie waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken."/>
            </Helmet>
            {/* First Container */}
            <div className="home-banner">

                <div className="waves-top">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                    </svg>
                </div>

                <div className="row pt-3">
                    <div className="col-lg-12 col-sm-12 col-xs-12">                        
                        <div className="text-banner text-center">
                            <h2 className="pt-3">Digitale Burnout Applicatie <i className="fas fa-mobile-alt"></i></h2>
                            <p className="subt"><i className="fa fa-angle-double-right"></i> Welkom</p>
                            <p className="py-2">Maak kennis met de allereerste applicatie die burnoutklachten op een wetenschappelijk verantwoorde manier meet.</p>
                        </div>
                        <div className="item-center py-2 ">                                
                            <Link to="/burnout-app" className="btn btn-white">Ontdek de Applicatie <i className="fas fa-arrow-circle-right"></i></Link>                                
                        </div>
                        <div className="text-banner text-center pt-4">            
                            <p className="subt"><i className="fas fa-fire"></i> Brandend actueel </p>
                            <p>Hoe kunnen we persoonlijk leiderschap ontwikkelen in het voorkomen van burnout als werkgerelateerde mentale aandoening, die een (pan)epidemisch verloop kent in de actuele VUCA(S) wereld en gekenmerkt wordt door disruptie of ontwrichting.</p>
                                            
                            <div className="item-center">
                            <img src="images/welkom.png" className="app-image" alt="Een mobiele en een webapplicatie. DBA©"/>                                                       
                    </div>
                                               

                            <p className="pt-3">Ontdek binnenkort ons PREMIUM aanbod en geniet van diverse functionaliteiten gericht op burnout preventie.</p>
                            <div className="item-center py-4 ">                                
                                <Link to="/dba-premium" className="btn btn-white">Ontdek DBA © PREMIUM <i className="fas fa-arrow-circle-right"></i></Link>                                
                            </div>    
                        </div>      
                    </div>
                </div>
            </div>
            
            {/* Second Container */}
            <div className="element-one ">
                <div className="wave-top mb-3">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill orange"></path>
                    </svg>
                </div>
                
                <div className="row">
                    
                        <div className="text-box item-center p-2">
                            <div className="head-box">
                                <h2 className="text-purple">
                                    <i className="fa fa-angle-double-right"></i>
                                    Ons GRATIS aanbod
                                </h2> 
                                <img src="images/premium-2.png" id="no-bg" alt="DBA©"/> 
                                <div className="head-box max-w-p-small py-3">
                                    <h4>Je kan kostenloos de Digitale Burnout Applicatie ® gebruiken, zowel de <span className="text-purple">WEB Applicatie</span> en weldra de <span className="text-purple">MOBIELE Applicatie</span>!</h4>
                                </div>       
                                                                                         
                                {/* <div className="row pb-4 ">   <h4 className="icons-center"><i className="fas fa-mobile-alt"></i><i className="fas fa-desktop"></i></h4>
                                </div> */}
                            </div>                        
                        </div> 
                </div>
            </div>
             <section className="info-section bg-white">
                <div className="three-panel-block mt-2 mb-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="service-block-overlay text-center mb-5 p-lg-3">
                                <i className="fa fa-laptop box-circle-solid  mb-3" aria-hidden="true"></i>
                                <h3>Webapplicatie</h3>
                                <p className="px-4">Ontdek de Digitale Burnout WEB Applicatie.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="service-block-overlay text-center mb-5 p-lg-3">
                                <i className="fas fa-mobile-alt box-circle-solid " aria-hidden="true"></i>
                                <h3>Mobiele applicatie</h3>
                                <p className="px-4">Ontdek binnenkort de Digitale Burnout Applicatie © voor MOBIELE toestellen.</p>
                            </div>
                        </div>
                    </div>  
                </div>                                                                              
            </section>
                

                <div className="wave-bottom mt-3">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                    </svg>
                </div>
         
            <div className="quote bg-purple">
                <p>
                <span className="quote-i">“</span> Een burnout is vooral een lichamelijke en een mentale <span className="text-orange">uitputtingsreactie</span>.
                <br></br>
                Hoe meer je snapt wat je wel en niet van jezelf kunt verwachten,
                hoe sneller je <span className="text-orange">lichaam en geest</span> kan herstellen. <span className="quote-i">”</span> 
                </p>
            </div>
            <div className="wave-top">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill purple"></path>
                    </svg>
                </div>
            {/* Third Container */}                        
            {/*<!-- Info block 1 --> */}
            <section className="info-section bg-white">
              
                <div className="container">
                    <div className="head-box text-center">
                        <h2>Ons PREMIUM aanbod</h2>
                        <h6 className="text-underline-primary py-3">Digitale Burnout Applicatie toegankelijk voor iedereen!</h6>
                        <Link to="/dba-premium" className="btn btn-white mt-4">Ontdek DBA © PREMIUM <i className="fas fa-arrow-circle-right"></i></Link>       
                    </div>
                    <div className="item-center">
                        <img src="images/premium.png" className="app-image" alt="DBA© Premium Features"/>
                    </div>

                    <div className="three-panel-block mt-2 mb-3">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="service-block-overlay text-center p-lg-3">
                                    <i className="fa fa-diamond box-circle-solid mt-3 mb-3" aria-hidden="true"></i>
                                    <h3>Premium</h3>
                                    <p className="px-4">Ontdek binnenkort ons PREMIUM aanbod. Krijg toegang tot unieke functionaliteiten gericht op burnout preventie.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="service-block-overlay text-center p-lg-3">
                                    <i className="fa fa-cloud-upload box-circle-solid mt-3 mb-3" aria-hidden="true"></i>
                                    <h3>Cloud Storage</h3>
                                    <p className="px-4">Data worden opgeslagen in de Cloud. Snel, veilig en geëncrypteerd!</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="service-block-overlay text-center mb-5 p-lg-3">
                                    <i className="fas fa-chalkboard-teacher box-circle-solid mt-3 mb-3" aria-hidden="true"></i>
                                    <h3>Chatbot</h3>
                                    <p className="px-4">Onze unieke BURNout-bot beantwoordt je specifieke vragen over burnout.</p>
                                </div>
                            </div>
                            
                      
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="service-block-overlay text-center p-lg-3">
                                    <i className="fa fa-comments box-circle-solid mt-3 mb-3" aria-hidden="true"></i>
                                    <h3>Communicatie</h3>
                                    <p className="px-4">Jouw mening is belangrijk! Daarom voorziet de DBA © een FEEDBACK functionaliteit, om jouw mening op te vragen en te integreren in onze applicatie.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>  
            </section>
          

            {/*<!-- Info block 2 --> */}
            <section className="info-section bg-purple py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 content-half mt-md-0 pl-5 pt-4">
                            <div className="head-box">
                                <h2 className="text-orange">Ons doel</h2>
                                <h6 className="text-white text-underline-rb-orange">De doelstelling van de Digitale Burnout Applicatie is gericht op het correct meten van burnout en preventief inzetten op een verdere ontwikkeling van de werkgerelateerde mentale aandoening "burnout.</h6>
                            </div>
                            <ul className="">
                                <li>
                                <i className="fas fa-laptop-medical" aria-hidden="true"></i>
                                    <span className="list-content">
                                        Streven naar een
                                        <strong> professioneel advies </strong>
                                        tegenover een volatiele berichtgeving.
                                    </span>
                                </li>
                                <li>
                                <i className="fas fa-user-shield" aria-hidden="true"></i>
                                    <span className="list-content">
                                        Streven naar  
                                        <strong> zekerheid </strong>
                                        in een wereld van onzekerheid.
                                    </span>
                                </li>
                                <li>
                                <i className="fas fa-tasks" aria-hidden="true"></i>
                                    <span className="list-content">
                                        Streven naar  
                                        <strong> kwalitatieve vereenvoudiging </strong>
                                        tegenover complexiteit.
                                    </span>
                                </li>
                                <li>
                                <i className="fas fa-chalkboard" aria-hidden="true"></i>
                                    <span className="list-content">
                                        Streven naar  
                                        <strong> duidelijkheid </strong>
                                        tegenover dubbelzinnigheid of ambiguïteit.
                                    </span>
                                </li>
                                <li>
                                <i className="fas fa-fast-forward" aria-hidden="true"></i>
                                    <span className="list-content">
                                        Streven naar  
                                        <strong> snelle </strong>
                                        tegenover trage interventies of acties.
                                    </span>
                                </li>
                            
                            </ul>                            
                        </div>
                     
                    </div>
                    <div className="row p-5">
                        <div className="col text-center">
                            <Link to="/ons-doel" className="btn btn-white">Lees meer <i className="fas fa-arrow-circle-right"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    )
}

export default Home
