import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
    return (     
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
            <a className="navbar-brand" href="/"><img src="/images/logo.png" alt="Digitale Burnout Applicatie logo" className="logo"/>Digitale Burnout Applicatie</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link active" href="/">Home</a>
                    </li>
                    <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Over Burnout
                        </Link>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="/wat-is-burnout">Wat is burnout?</Link></li>
                            <li><hr className="dropdown-divider"></hr></li>
                            <li><Link className="dropdown-item" to="/preventie">Burnout preventie</Link></li>
                            <li><hr className="dropdown-divider"></hr></li>
                            <li><Link className="dropdown-item" to="/burnout-en-stress">Burnout en stress</Link></li>                            
                  
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/burnout-app">Onze Applicatie</a>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/ons-doel">Ons Doel</Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/chatbot">Onze Chatbot</a>
                    </li>
                    <li className="nav-item">
                        
                        <a className="nav-link" href="mailto:info@burnoutapp.be"><i className="far fa-envelope"></i> info@burnoutapp.be</a>
                    </li>
                    <li className="nav-item">
                        
                        <a className="nav-link" href="https://digitaleburnoutapp.be" target="_blank" rel="noreferrer"><i className="fas fa-mobile-alt"></i> Digitale Burnout Applicatie</a>
                    </li>
           
                </ul>            
            </div>
        </div>
    </nav>

    )
}

export default Navbar
