import React from 'react'
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import MeerInfo from './MeerInfo';

function Burnout() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
      scrollToTop();

    return (
        <div className="home">
            <Helmet>
              <title>Digitale Burnout Applicatie © Wat is burnout?</title>
              <meta name="description" content="Een Mobiele Applicatie waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken. Bovendien kan je genieten van premium features gericht op burnout preventie. De Digitale Burnout Applicatie © heeft ook een unieke chatbot de 'BURNout-bot'." />
            </Helmet>           
            <section>
            <div className="wave-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
            </section>
            <section className="info-section">
            <div className="head-box text-center">
                        <h2 className="text-purple">Wat is burnout?</h2>
                        <div className="head-box">
                        <h6 className="text-underline-primary pb-2">
                            De benaming burnout verwijst naar de <span className="text-purple">gevoelens van uitputting</span> en zich <span className="text-fire">opgebrand voelen</span>.
                            Wetenschappers zijn het er meer dan ooit over eens dat burnout een <span className="text-purple">werkgerelateerde aandoening</span> is. 
                            De <span className="text-purple">diagnose</span> burnout is niet eenvoudig te stellen en kan <span className="text-purple">énkel door een deskundige -zoals een arts of een erkend psycholoog gespecialiseerd in burnout- vastgesteld worden</span>.
                        </h6>
                        <div className="head-box">
                            <div className="row pt-5"><i className="fas fa-info-circle text-purple"></i>
                            <p>
                               
                                Op deze pagina vind je meer informatie over de aandoening burnout:
                                <br></br>
                                je leert hoe een burnout zich kan ontwikkelen, hoe je symptomen kan herkennen en je leert aandacht te besteden aan zowel professionele als persoonlijke valkuilen eigen aan burnout.
                            </p></div> 
                            
                        </div>
                        </div>

                       
                    </div>
            </section> 
            <section>
                <div className="wave-bottom flip">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
            </section>          
          
            <section className="info-section bg-orange">             
                <div className="head-box ons-doel">
                    <h3 className="text-purple">
                        De aandoening burnout
                    </h3>                   
                    <div className="head-box pt-2 pb-2 "> 
                        <p>
                            De voorbije jaren kreeg burnout zeer veel aandacht. Dit is niet verwonderlijk omdat zeer veel werknemers ten prooi vielen van deze werkgerelateerde mentale aandoening.
                        </p>
                        <p>
                            De definitie van burnout en de bijhorende vragenlijsten waren meer dan dertig jaar oud en werden in 2020 concreet en duidelijk afgebakend in een wetenschappelijk onderbouwd model. De onderzoekers van de KU Leuven diagnosticeerden en definieerden vier kernsymptomen en drie bijkomstige symptomen eigen aan burnout.
                        </p> 
                        <h5 className="text-orange px-2">Burnout detecteren</h5>
                        <p>
                            Door gebruik te maken van een verbeterde, onderbouwde vragenlijst kan er een betere diagnose van burnout gesteld worden en kan arbeidsverzuim op termijn teruggedrongen worden.
                            Symptomen zoals stress, depressie, angst en burnout worden duidelijker waardoor zowel de werkgever als de werknemer effectiever kunnen ingrijpen.
                        </p>                       
                        <div className="row pt-2 pb-2">
                            <div className="col text-center">
                                <Link to="/burnout-app" className="btn btn-white">Onze applicatie <i className="fas fa-arrow-circle-right"></i></Link>
                            </div>
                        </div>

                        <h5 className="text-orange px-2">Symptomen</h5>
                        <p>
                            Ben je meer prikkelbaar, voel je je angstig en onrustig en pieker je meer dan ooit over het werk?
                            <br></br>
                            Dit zijn mogelijke symptomen van een burnout. Herken deze signalen tijdig want voorkomen van een burnout is beter dan genezen. 
                        </p>
                        <p>
                            Een burnout wordt niet alleen gekenmerkt door mentale symptomen maar ook door lichamelijke kwalen zoals hoofdpijn, migraine, een vermoeid lichaam, nekpijn, rugpijn en een gevoel van mentale vermoeidheid.
                        </p>                
                        <h5 className="text-orange px-2">Spanningsklachten</h5>
                        <p>
                            Naast de hoofdklachten eigen aan burnout kan dit gepaard gaan met bijkomende spanningsklachten zoals piekeren, slaapproblemen, prikkelbaarheid, duizeligheid, ademhalingsproblemen, hyperventilatie, .. 
                        </p>
                        <p>
                            De klachten eigen aan burnout kan je meten met de burnout vragenlijst.
                        </p>

                        <h5 className="text-fire px-2"><i className="fas fa-exclamation-triangle text-fire"></i> Alarmsignalen</h5>
                        <p>
                            Het allereerste alarmsignaal eigen aan burnout is verandering in het gedrag van de medewerker. De aanvankelijk zeer stipte medewerker komt regelmatig te laat op het werk. Vroeger nam hij vaak het woord op vergaderingen maar keer op keer verdwijnt hij naar de achtergrond.
                        </p>
                        <ul>
                            <li>De medewerker voelt zich heel dikwijls moe, leeg en uitgeput.</li>
                            <li>De medewerker lijkt wel uitgeblust en beschikt niet meer over de nodige energie om normaal te kunnen functioneren.</li>
                        </ul>
                            


                    

                    </div>

                    <hr></hr>
                


                    <h3 className="text-purple">
                        De duur van een burnout
                    </h3>    
                    <div className="head-box pt-2 pb-2 ">  
                         <p>
                            Er bestaat geen standaardoplossing voor de behandeling van een burnout. Het herstel is een strikt individueel gebeuren. Wat voor jou werkt, werkt niet per se voor een andere.
                        </p>
                        <h5 className="text-orange px-2">Onstaan van een burnout</h5>
                        <p> 
                            Je burnout is niet van de ene dag op de andere ontstaan, integendeel zelfs. De ontwikkeling van een burnout is een langdurig proces geweest en waarbij je de signalen van je lichaam, keer op keer hebt genegeerd. Door goed te kijken in je persoonlijke spiegel, kan je leren om te luisteren naar de signalen die je lichaam geeft. Deze signalen liegen nooit. 
                            Je sympatisch zenuwstelsel sympatiseert met jou en geeft tijdig signalen wanneer je vermoeid raakt. Door goed te luisteren naar deze signalen zoals nekpijn of lage rugpijn kan je tijdig ontspannen, rustpauzes nemen en door tijdig te recupereren kan je een tweede burnout voorkomen.
                        </p>
                       

                        <h5 className="text-orange px-2">Einde van een burnout</h5>
                        <p>
                            De herstelduur van een burnout verschilt van persoon tot persoon. Een burnout is nooit helemaal voorbij. 
                            Om te herstellen van een burnout en opnieuw te kunnen functioneren moet je op heel veel tijd rekenen. 
                            Soms duurt het een paar maanden, soms een paar jaar. Hoe sneller je wilt genezen, hoe trager het gaat. Begeleiding met een deskundige partner is de meest aangewezen oplossing.
                        </p>
                        <h5 className="text-orange px-2">Herstellen</h5>
                        <p>
                            Er bestaat geen standaardpatroon voor het herstel van een burnout. Het is een strikt individueel gebeuren en verloopt met ups en downs. 
                            Het is een grillig patroon. 
                            De symptomen houden zich niet altijd aan een concrete logica of structuur. Een professionele begeleiding door een deskundige partner is altijd aangewezen.
                        </p>
                    </div>

                    <hr></hr>

                    <h3 className="text-purple">
                        Ontwikkeling van een burnout
                    </h3>       
                    <div className="head-box pt-2 pb-2 "> 
                        <h5 className="text-orange px-2">Beperk de kans tot ontwikkeling van een burnout als mentale aandoening</h5>
                        <p>
                            Luister naar je lichaam. Voel je al een tijdje dat je energie wegstroomt en er teveel op je af komt. 
                            Grijp dan snel in.
                            <br></br>
                            De klachten eigen aan burnout kunnen gemeten worden met onze applicatie.
                        </p>
                        <p>
                            Iedereen komt vroeg of laat wel eens onder druk of spanning te staan. Of je al dan niet een burnout als mentale aandoening gaat ontwikkelen hangt af van een combinatie van factoren.
                        </p>
                        <h5 className="text-orange px-2">Professionele valkuilen </h5>
                        <p>
                            Professionele triggers op het werk:
                        </p>
                        <ul>
                            <li>De grote hoeveelheid werk</li>
                            <li>Werken onder tijdsdruk</li>
                            <li>Strenge kwaliteiseisen</li>
                            <li>Belangenconflicten</li>
                            <li>Emotionele belasting</li>
                            <li>Balans werk en privé</li>
                            <li>Weinig autonomie</li>
                            <li>Onduidelijkheid over je taken</li>
                        </ul>
                        <h5 className="text-orange px-2">Persoonlijke valkuilen </h5>
                        <p>Persoonlijke triggers op het werk:</p>
                        <ul>
                            <li>Sterke gewetensvolheid</li>
                            <li>Groot verantwoordelijkheidsgevoel</li>
                            <li>Perfectionisme</li>
                            <li>Pleasegedrag</li>
                            <li>Privéproblemen </li>
                            <li>Sterk samenhorigheidsgevoel</li>
                            <li>Volhardend</li>
                            <li>Sterke gedrevenheid en ambitie</li>
                        </ul>
                        <p>
                            Wanneer verschillende persoonlijke triggers samenkomen, kan het risico op de ontwikkeling van een burnout toenemen waardoor je je werk niet meer optimaal kan uitvoeren.
                        </p>
                    </div>
      
                    <hr></hr>

                    <div className="row pt-2 pb-2">
                        <div className="col text-center">
                            <Link to="/preventie" className="btn btn-white">Burnout Preventie <i className="fas fa-arrow-circle-right"></i></Link>
                        </div>
                    </div>

                    <hr></hr>
                    <MeerInfo/>
                    
                    
                            
                                
                </div>
                     
               
      
            </section>
            
        </div>
    )
}
export default Burnout
