import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './BurnoutApp.css';

function BurnoutApp() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
      scrollToTop();

      
      
    return (
        <div className="bo-app ">   
            <Helmet>
              <title>Digitale Burnout Applicatie © Onze App</title>
              <meta name="description" content="Een Mobiele Applicatie waarbij je GRATIS de BAT vragenlijsten kan invullen en je resultaten onmiddellijk kan bekijken. Bovendien kan je genieten van premium features gericht op burnout preventie. De Digitale Burnout Applicatie © heeft ook een unieke chatbot de 'BURNout-bot'." />
            </Helmet>            
            <section className="item-center ">
                <h1 className="text-purple">Ontdek de Digitale Burnout Applicatie</h1>    
                <img src="/images/logo-app.png" alt="Digitale Burnout Applicatie" className="img-fluid logo"/>
                <i className="fas fa-arrow-circle-down"></i>
                            
                <div className="wave-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
            </section>        
            
             <section className="orange item-center">
                <div className="head-box">          
                    <h1 className="text-purple" id='onze-app'>Wat?</h1>                      
                    <h5 className="text-underline-purple pb-2">Een wetenschappelijk onderbouwde applicatie, gericht op burnout detectie en preventie.</h5>
                </div> 
                <h4>Je kan kostenloos de Digitale Burnout Applicatie ® gebruiken, zowel de <span className="text-purple">WEB Applicatie</span> en weldra de <span className="text-purple">MOBIELE Applicatie</span>!</h4>
                <img src="images/promote-DBA.png" className="app-image" alt="DBA© Welkom Pagina"/>                
               
                <p>Bepaal snel en met weinig inspanning of je al dan niet tot de risicogroep behoort om burnout als een mentale aandoening te ontwikkelen.</p>   
                
                <div className="row pt-2 pb-2">
                <div className="col text-center">
                    <Link to="/dba" className="btn btn-white">OPEN de Digitale Burnout Applicatie © <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
            </div>
               
                <div className="wave-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
            </section>

            <section className="item-center  text-dark">
                <div className="wave-top flip">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
                <div className="head-box">          
                    <h1 className="text-purple">Hoe?</h1>                     
                    <h5 className="text-underline-purple pb-2">
                        Met behulp van de Burnout Assessment Tool (BAT)
                        <br></br>
                        <span className="text-purple">- een wetenschappelijk onderbouwde vragenlijst -</span>
                        <br></br>
                        kan een antwoord worden gegeven op de vraag:<br></br> "Heb ik een burnout?"
                    </h5>
                </div>            
                <p className="max-w-p-small">Via de Digitale Burnout Applicatie bieden we de uitvoerige versie met <span className="text-purple">23</span> vragen en de verkorte versie met <span className="text-purple">12</span> vragen.</p>                
                <p className="p-small"><i className="text-purple fa fa-angle-double-right"></i> Met de 12 vragen meet je <span className="text-purple">reeds</span> de mate van burnout.</p>
                <p className="p-small"><i className="text-purple fa fa-angle-double-right"></i> Met de 23 vragen meet je <span className="text-purple">zeker</span> de mate van burnout.</p>
                <img src="images/app-bat.png" className="app-image" alt="DBA© BAT vragenlijsten"/>
                
       
                <p className="max-w-p-small">Na het aanmaken van een account krijg je GRATIS toegang tot de Burnout Assessment Tool (BAT).<br></br>Bij invullen van een BAT vragenlijst krijg je onmiddelijk je resultaat te zien.</p>
                    <br></br>
                    <p className="max-w-p-small">Door gebruik te maken van het wetenschappelijk onderbouwd </p>
                    <p className="text-large"><span className="text-red">stop</span><span className="text-orange">licht</span><span className="text-green">model</span></p>
                    <p className="pb-3">kunnen je burnoutklachten in kaart gebracht worden.</p>
                    <p id='info-p'>Meer info over de BAT: <a  href="https://burnoutassessmenttool.be/#handleidingenvragenlijst" rel="noreferrer" target="_blank">burnoutassessmenttool.be</a></p>
         
                            
                               
            

                <img src="images/app-stoplicht.png" className="app-image" alt="DBA© Stoplichtmodel"/>

                

                <div className="wave-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill purple"></path>
                    </svg>
                </div>
            </section>

            <section className="purple item-center">
            <div className="head-box">          
                <h1 className="text-orange">Waarom?</h1>                     
                <h5 className="text-underline-white pb-2">Een concrete bijdrage leveren aan het algemeen welzijn met de focus op mensen in een werkomgeving, zowel werkgevers als werknemers.</h5>
            </div>        
            <p className="text-orange pt-2">De doelstelling van de Digitale Burnout Applicatie is gericht op het correct meten van burnout en preventief inzetten op een verdere ontwikkeling van de werkgerelateerde mentale aandoening "burnout".</p>
            <div className="row pt-4 ">
                <div className="col text-center">
                    <Link to="/ons-doel" className="btn btn-white">Lees hier meer <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
            </div>
            </section>

            <section className="item-center text-dark">
                <div className="wave-top flip">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill purple"></path>
                    </svg>
                </div>
                <div className="head-box">          
                    <h1 className="text-purple">Voor wie?</h1>              
                    <h5 className="text-underline-purple pb-2">De Digitale Burnout Applicatie is en blijft toegankelijk voor iedereen!</h5>
                </div> 
                <img src="images/iedereen.png" className="max-img-w" alt="DBA© is GRATIS voor iedereen beschikbaar."/>
                <p className="pt-3">We willen echter graag extra aandacht besteden aan de doelgroep <span className="text-purple">verpleegkundigen</span>, die momenteel zwaar onder druk staan als gevolg van onder andere de Covid 19 pandemie.</p>
                <p className="pt-3 pb-5">Bijkomend zouden we in het kader van onze twee doelstellingen, persoonlijk leiderschap en preventie, aandacht willen besteden aan de doelgroepen <span className="text-purple">studenten </span>en mensen die <span className="text-purple">starten met een opleiding</span>.</p>          
                {/* <img src="images/qr.png" className="max-img-w" alt="DBA© is GRATIS voor iedereen beschikbaar."/> */}
                <div className="wave-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill orange"></path>
                    </svg>
                </div>
            </section>


   
            
        </div>
    )
}

export default BurnoutApp
